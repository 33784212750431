<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    page: {
        title: "Buat pendaftran sertifikasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Buat pendaftaran sertifikasi",
            items: [
                {
                    text: "Pendaftaran",
                    href: "/pendaftaran",
                },
                {
                    text: "Buat Pendaftaran Sertifikasi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],
            skema_sertifikasi_selected: [],

            //modal
            showModalSimpan: false,
            asesi: [],
            showModalEditPemilihanAsesi: false,
            optionsSkemaSertifikasi: [],
            showModalPemilihanAsesi: false,
            noEdit: null,
            pemilihan_asesi: [],
            Jadwal: [],
            no_surat_pendaftaran: null,
            kode_jadwal: null,
            tanggal_surat_permohonan: new Date().toISOString().slice(0, 10),
            perihal: "Surat Permohonan Sertifikasi",
            id_departemen: null,
            nama_pic: localStorage.getItem("session_name"),
            pegawai_id: localStorage.getItem("session_pegawai_id"),
            nama_departemen: null,
            pic_id: null,
            pembuat: localStorage.getItem("session_name"),
            sso_user: JSON.parse(localStorage.getItem("sso_user")),
            sso_user_detail: JSON.parse(localStorage.getItem("sso_user_detail")),
            diubah_tanggal: new Date().toISOString().slice(0, 10),
            optionsAsesi: [],
            urutan_nomor: null,
        };
    },
    mounted() {
        let self = this;
        self.getSkemaSertifikasi();
        self.getPegawai();
        self.getNomorPendaftaran();
        if(self.pegawai_id != null && self.pegawai_id != 'null'){
            self.getDepartemen();
        }
        // self.nama_departemen = self?.sso_user_detail[0]?.DepartmentName;
        // self.id_departemen = self?.sso_user_detail[0]?.DepartmentId;
        // self.pic_id = self.sso_user?.UserId;
        console.log(self.pic_id);
    },
    methods: {
        getSkemaSertifikasi() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-muk-terbit",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDepartemen() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pegawai-departemen",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                params: {
                    pegawai_id: self.pegawai_id
                }
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.nama_departemen = response_data_fix?.referensi?.direktorat;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getPegawai() {
            // GET MASTER KARYAWAN
            let self = this;

            // load data master karyawan
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pegawai",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsAsesi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getNomorPendaftaran() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/get-nomor-pendaftaran", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    console.log(currentDate);
                    self.no_surat_pendaftaran = next_nomor + "/JPS/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                    self.kode_jadwal = next_nomor + "/JP/" + self.convertToRoman(currentDate.getMonth()) + "/" + currentDate.getFullYear();
                    self.urutan_nomor = next_nomor;
                    Swal.close();
                })
                .catch((e) => {
                    console.log(e);
                    // this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        modalSimpan() {
            let self = this;
            self.showModalPemilihanAsesi = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalPemilihanAsesi = true;
            });
        },
        modalEditPemilihanAsesi(index) {
            let self = this;
            self.showModalEditPemilihanAsesi = false;
            // // config untuk re render component
            self.$nextTick(() => {
                self.showModalEditPemilihanAsesi = true;
            });
            this.noEdit = index;
            this.skema_sertifikasi_selected = this.pemilihan_asesi[index].skema_sertifikasi_selected;
        },
        simpanSkema() {
            this.pemilihan_asesi.push({
                skema_sertifikasi_selected: this.skema_sertifikasi_selected,
                nama_skema_sertifikasi: this.skema_sertifikasi_selected.skema_nama,
                id_skema_sertifikasi: this.skema_sertifikasi_selected.skema_id,
                asesi: this.asesi,
                JumlahAsesi: this.asesi.length,
                kode: Math.floor(Math.random() * 100) + 1 + "/HC/UM/VIII/2023",
                no_surat_pendaftaran: this.no_surat_pendaftaran,
                tanggal_surat_permohonan: this.tanggal_surat_permohonan,
                perihal: this.perihal,
                nama_pic: this.nama_pic,
                nama_departemen: this.nama_departemen,
                pembuat: this.pembuat,
                diubah_tanggal: this.diubah_tanggal,
            });
            this.showModalPemilihanAsesi = false;
        },
        editSkema(index) {
            this.pemilihan_asesi[index] = {
                skema_sertifikasi_selected: this.skema_sertifikasi_selected,
                nama_skema_sertifikasi: this.skema_sertifikasi_selected.skema_nama,
                id_skema_sertifikasi: this.skema_sertifikasi_selected.skema_id,
                asesi: this.asesi,
                JumlahAsesi: this.asesi.length,
                kode: Math.floor(Math.random() * 100) + 1 + "/HC/UM/VIII/2023",
                no_surat_pendaftaran: this.no_surat_pendaftaran,
                tanggal_surat_permohonan: this.tanggal_surat_permohonan,
                perihal: this.perihal,
                nama_pic: this.nama_pic,
                nama_departemen: this.nama_departemen,
                pembuat: this.pembuat,
                diubah_tanggal: this.diubah_tanggal,
            };
            this.showModalEditPemilihanAsesi = false;
        },
        modalTambahPemilihanAsesi() {
            let self = this;
            self.showModalPemilihanAsesi = true;
            this.skema_sertifikasi_selected = [];
            this.asesi = [];
        },
        redirectPendaftaran() {
            localStorage.setItem("Jadwal", JSON.stringify(this.pemilihan_asesi));
            this.$router.push({
                name: "all-pendaftaran",
            });
        },
        addAsesi() {
            this.asesi.push({
                nama_asesi: null,
                usulan_tanggal: new Date().toISOString().slice(0, 10),
            });
        },
        removeAsesi(index) {
            this.asesi.splice(index, 1);
        },
        removePemilihanAsesi(index) {
            this.pemilihan_asesi.splice(index, 1);
        },

        uploadSuratPermohonan() {
            let self = this;
            if ($("#file")[0].files[0]) {
                if ($("#file")[0].files[0].size < 2242880) {
                    $("#uploadLoading").html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#file")[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config).then(function (response) {
                        console.log(response.data.data.path_file);
                        var path_file = response.data.data.path_file;
                        urlres += path_file;
                        $("#uploadLoading").html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>');
                        self.surat_permohonan_sertifikasi = path_file;
                    });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },

        submitPendaftaran(status) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var send_status = "14ee35ae-3c10-11ee-a5ac-0242ac110009";
            if (status == "ajukan") {
                send_status = "1cc9196a-3c10-11ee-a5ac-0242ac110009";
            }

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-pendaftaran",
                data: {
                    pendaftaran_nomor: self.no_surat_pendaftaran,
                    kode_jadwal: self.kode_jadwal,
                    pendaftaran_tanggal: self.tanggal_surat_permohonan,
                    perihal: self.perihal,
                    pic_id: self.pic_id,
                    pic_nama: self.nama_pic,
                    departemen_id: self.id_departemen,
                    departemen_nama: self.nama_departemen,
                    status_id: send_status,
                    pendaftaran_asesi: JSON.stringify(self.pemilihan_asesi),
                    path_surat_permintaan: self.surat_permohonan_sertifikasi,
                    urutan_nomor: self.urutan_nomor,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list pendaftaran segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            self.showModalEditPemilihanAsesi = false;
                            self.showModalPemilihanAsesi = false;
                            self.$router.push({ name: "all-pendaftaran" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <span class="text-danger">{{ errorArray }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Surat Permohonan <span class="required-field"></span></label>
                                                <input type="text" class="form-control" v-model="no_surat_pendaftaran" />
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Surat Permohonan <span class="required-field"></span></label>
                                                <input type="date" class="form-control" v-model="tanggal_surat_permohonan" />
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal </label>
                                                <input type="text" class="form-control" readonly v-model="perihal" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nama PIC <span class="required-field"></span></label>
                                                <input type="text" class="form-control" v-model="nama_pic" />
                                            </div>

                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nama Departemen</label>
                                                <input type="text" class="form-control" v-model="nama_departemen" />
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Upload Surat Permohonan Sertifikasi</label>
                                                <input class="form-control" type="file" id="file" accept="application/pdf" v-on:change="uploadSuratPermohonan()" />
                                                <div class="respond-input-file float-left" id="uploadLoading"></div>
                                                <small class="float-right" style="color: red">*).pdf Max File 2 MB</small>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table mb-0 table-bordered table-condensed table-hover mx-2 mt-2">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th>No</th>
                                                <th style="width: 60%">Skema Sertifikasi</th>
                                                <th>Asesi</th>
                                                <th>Usulan Tanggal Asesmen</th>
                                                <th>Aksi</th>
                                                <td class="text-center">
                                                    <button class="btn btn-success btn-sm" @click="modalTambahPemilihanAsesi"><i class="bx bx-plus"></i></button>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="pemilihan_asesi.length == 0">
                                                <td colspan="6">Belum ada data</td>
                                            </tr>
                                            <tr v-else v-for="(val, key) in pemilihan_asesi" :key="key">
                                                <td class="text-center">{{ key + 1 }}</td>
                                                <td>
                                                    {{ val.nama_skema_sertifikasi }}
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li v-for="(val, key) in val.asesi" :key="key">
                                                            {{ val.nama_asesi?.nama }}
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li v-for="(val, key) in val.asesi" :key="key">
                                                            {{ val.usulan_tanggal }}
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-primary" @click="modalEditPemilihanAsesi(key)"><i class="bx bx-edit"></i></button>
                                                </td>
                                                <td>
                                                    <div class="btn btn-danger btn-sm" v-on:click="removePemilihanAsesi(key)"><i class="bx bx-minus"></i></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <button v-on:click="submitPendaftaran('draft')" class="btn btn-warning" type="button"><i class="fas fa-edit"></i> Simpan Draft</button>
                                        <button class="btn btn-primary bg-primary m-1" v-on:click="submitPendaftaran('ajukan')"><i class="fas fa-save"></i> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>

    <!-- modal -->
    <b-modal v-model="showModalPemilihanAsesi" title="Tambah Asesi" title-class="text-black font-18" body-class="p-3" size="xl" hide-footer>
        <div class="row mx-2">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group col-md-12">
                            <label for="tipe_menu">Skema</label>
                            <v-select :options="optionsSkemaSertifikasi" label="skema_nama" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"></v-select>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                    <div class="col-lg-12">
                        <table class="table mb-0 table-bordered table-condensed table-hover mx-2 mt-4">
                            <thead class="bg-dark text-center text-white">
                                <tr>
                                    <th style="width: 50px">No</th>
                                    <th style="width: 50%">Asesi</th>
                                    <th>Usulan Tanggal Asesmen</th>
                                    <th style="width: 50px">
                                        <div class="btn btn-success btn-sm" v-on:click="addAsesi"><i class="bx bx-plus"></i></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr v-if="asesi.length == 0">
                                    <td colspan="4">Belum ada data</td>
                                </tr>
                                <tr v-else v-for="(val, key) in asesi" :key="key">
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>
                                        <v-select :options="optionsAsesi" label="nama" v-model="val.nama_asesi" placeholder="Pilih Nama Asesi"></v-select>
                                    </td>
                                    <td>
                                        <input type="date" class="form-control" v-model="val.usulan_tanggal" />
                                    </td>
                                    <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="removeAsesi(key)"><i class="bx bx-minus"></i></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12 col-md-6">
                        <div class="text-end">
                            <b-button v-on:click="simpanSkema" type="submit" variant="primary" class="m-1"> <i class="fa fa-save"></i> Simpan </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalEditPemilihanAsesi" title="Edit Asesi" title-class="text-black font-18" body-class="p-3" size="xl" hide-footer>
        <div class="row mx-2">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group col-md-12">
                            <label for="tipe_menu">Skema</label>
                            <v-select :options="optionsSkemaSertifikasi" label="skema_nama" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"></v-select>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>
                <table class="table mb-0 table-bordered table-condensed table-hover mx-2 mt-4">
                    <thead class="bg-dark text-center text-white">
                        <tr>
                            <th>No</th>
                            <th>Asesi</th>
                            <th>Usulan Tanggal Asesmen</th>
                            <th style="width: 50px">
                                <div class="btn btn-success btn-sm" v-on:click="addAsesi"><i class="bx bx-plus"></i></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-if="asesi.length == 0">
                            <td colspan="4">Belum ada data</td>
                        </tr>
                        <tr v-for="(val, key) in asesi" :key="key">
                            <td class="text-center">{{ key + 1 }}</td>
                            <td>
                                <v-select :options="optionsAsesi" label="nama" v-model="val.nama_asesi" placeholder="Pilih Nama Asesi"></v-select>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="val.usulan_tanggal" />
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" v-on:click="removeAsesi(key)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row mt-2">
                    <div class="col-md-12 col-md-6">
                        <div class="text-end">
                            <b-button type="reset" variant="danger"> <i class="fa fa-redo-alt"></i> Reset </b-button>
                            <b-button v-on:click="editSkema(this.noEdit)" type="submit" variant="primary" class="m-1"> <i class="fa fa-save"></i> Simpan </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<style scoped>
.required-field::before {
    content: "*";
    color: red;
}
</style>
